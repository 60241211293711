import { appURLs } from "@/app/app-urls.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import ApexDef, { GAME_SYMBOL_APEX } from "@/game-apex/definition.mjs";
import CS2Def, { GAME_SYMBOL_CS2 } from "@/game-csgo/definition.mjs";
import DestinyDef, {
  GAME_SYMBOL_DESTINY2,
} from "@/game-destiny2/definition.mjs";
import EFTDef, { GAME_SYMBOL_EFT } from "@/game-eft/definition.mjs";
import FortniteDef, {
  GAME_SYMBOL_FORTNITE,
} from "@/game-fortnite/definition.mjs";
import LoLDef, { GAME_SYMBOL_LOL } from "@/game-lol/definition.mjs";
import MarvelRivalsDef, {
  GAME_SYMBOL_MARVEL_RIVALS,
} from "@/game-marvel-rivals/definition.mjs";
import PalworldDef, {
  GAME_SYMBOL_PALWORLD,
} from "@/game-palworld/definition.mjs";
import TFTDef, { GAME_SYMBOL_TFT } from "@/game-tft/definition.mjs";
import ValDef, { GAME_SYMBOL_VAL } from "@/game-val/definition.mjs";
import ApexLogo from "@/inline-assets/apex.svg";
import ApexKda from "@/inline-assets/apex-kda.svg";
import AvatarIcon from "@/inline-assets/avatar-account.svg";
import LightningIcon from "@/inline-assets/blitz-colorless.svg";
import PlayIcon from "@/inline-assets/button-play-icon.svg";
import CompIcon from "@/inline-assets/game-guides-comps.svg";
import LeaderboardIcon from "@/inline-assets/game-guides-leaderboards.svg";
import MapIcon from "@/inline-assets/game-guides-map.svg";
import HealthIcon from "@/inline-assets/hextech-stat-health.svg";
import AramIcon from "@/inline-assets/lol-role-aram.svg";
import RuneDomination from "@/inline-assets/lol-rune-domination.svg";
import ApexMaster from "@/inline-assets/marketing-apex-master.svg";
import AugmentIcon from "@/inline-assets/marketing-augment-overlay.svg";
import PostmatchEval from "@/inline-assets/marketing-postmatch-eval.svg";
import TFTSet35 from "@/inline-assets/marketing-set-3_5.svg";
import BattlesIcon from "@/inline-assets/nav-battles.svg";
import DatabaseIcon from "@/inline-assets/nav-game-guide.svg";
import OverlayIcon from "@/inline-assets/overlays.svg";
import ProBuildsIcon from "@/inline-assets/pro-builds-icon.svg";
import StatisticsIcon from "@/inline-assets/statistics.svg";
import type { Publisher } from "@/marketing/constants/publishers.mjs";

type Feature = {
  icon: string | SVGComponent;
  title: Translation;
  desc: Translation;
  bgImg: string;
  src: string;
};

type HeroImg = {
  src: string;
  alt: Translation;
};

type HeroVideo = {
  mp4: string;
  webm?: string;
  poster?: string;
  alt: Translation;
};

type HeroMedia =
  | {
      heroImg?: HeroImg;
      heroVideo: HeroVideo;
    }
  | {
      heroImg: HeroImg;
      heroVideo?: HeroVideo;
    };

type GameData = HeroMedia & {
  color: string;
  bg: {
    img: string;
  };
  headline: Translation;
  compliancy?: Publisher;

  usps: { label: Translation; icon: string | SVGComponent; uri?: string }[];
  featureTitle: Translation;
  features: [Feature, Feature, Feature];
};

export const GAME_DATA_MAP: PartialRecord<GameSymbol, GameData> = {
  [GAME_SYMBOL_PALWORLD]: {
    color: PalworldDef.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/palworld/bg-img_1.webp`,
    },
    headline: [
      "welcome:palworldHeadline",
      "Locate any Pal or Item by Downloading the Free Blitz Desktop App",
    ],
    compliancy: "pocketpair",
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/palworld/hero-img_1.webp`,
      alt: ["welcome:palworldHeroAlt", "Demo of palworld map overlay"],
    },
    usps: [
      {
        label: ["welcome:palworld.usp.locatePal", "Locate Pals"],
        icon: `${appURLs.CDN}/welcome/main/palworld/usp-pal-icon.webp`,
        uri: "/palworld/database/pals",
      },
      {
        label: ["welcome:palworld.usp.locateMat", "Find Materials"],
        icon: `${appURLs.CDN}/welcome/main/palworld/usp-mat-icon.webp`,
        uri: "/palworld/database/materials",
      },
      {
        label: [
          "welcome:palworld.usp.collectable",
          "Keep track of your Friends",
        ],
        icon: `${appURLs.CDN}/welcome/main/palworld/usp-collectable-icon.webp`,
        uri: "/overlays/palworld",
      },
      {
        label: [
          "welcome:palworld.usp.tierList",
          "Combat/Work/Mount Tier Lists",
        ],
        icon: `${appURLs.CDN}/welcome/main/palworld/tier-list-icon.webp`,
        uri: "/palworld/tier-list/combat",
      },
    ],
    featureTitle: [
      "welcome:palworldFeatureTitle",
      "Unique and specialized features you can’t get anywhere else.",
    ],
    features: [
      {
        title: ["welcome:palworld.feature.compass", "Compass"],
        desc: [
          "welcome:palworld.feature.compassDesc",
          "Keep track of Pals, items, and your friends using Blitz's compass.",
        ],
        icon: `${appURLs.CDN}/welcome/main/palworld/feat-comp-icon.webp`,
        bgImg: `${appURLs.CDN}/welcome/main/palworld/feat-comp-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/palworld/feat-comp-img_1.webp`,
      },
      {
        title: ["welcome:palworld.feature.search", "Map Search Overlay"],
        desc: [
          "welcome:palworld.feature.searchDesc",
          "Search for items you need and see where they are located on the map.",
        ],
        icon: `${appURLs.CDN}/welcome/main/palworld/feat-search-icon.webp`,
        bgImg: `${appURLs.CDN}/welcome/main/palworld/feat-search-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/palworld/feat-search-img_1.webp`,
      },
      {
        title: ["welcome:palworld.feature.calc", "Breeding Calculator"],
        desc: [
          "welcome:palworld.feature.calcDesc",
          "Create the best Pal to level up your gaming by knowing exactly what you will get when breeding.",
        ],
        icon: `${appURLs.CDN}/welcome/main/palworld/feat-calc-icon.webp`,
        bgImg: `${appURLs.CDN}/welcome/main/palworld/feat-calc-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/palworld/feat-calc-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_FORTNITE]: {
    color: FortniteDef.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/fortnite/bg-img_1.webp`,
    },
    headline: [
      "welcome:fortniteHeadline.levelup",
      "Level up your teamwork! Optimize your squad's loadout with the Blitz App",
    ],
    compliancy: "epic",

    heroVideo: {
      mp4: `${appURLs.CDN_VIDEOS}/ui/video/welcome/welcome-fortnite.mp4`,
      webm: `${appURLs.CDN_VIDEOS}/ui/video/welcome/welcome-fortnite.webm`,
      poster: `${appURLs.CDN}/welcome/welcome-fortnite-overlay.png`,
      alt: ["welcome:fortniteHeroAlt", "Demo of Blitz profile page"],
    },
    usps: [
      {
        label: ["welcome:usp.statistics", "Statistics"],
        icon: StatisticsIcon,
        uri: "/fortnite/stats",
      },
      {
        label: ["welcome:usp.leaderboards", "Leaderboards"],
        icon: LeaderboardIcon,
        uri: "/fortnite/leaderboard",
      },
      {
        label: ["welcome:usp.database", "Database"],
        icon: DatabaseIcon,
        uri: "/fortnite/database",
      },
      {
        label: ["welcome:usp.overlay", "Overlays"],
        icon: OverlayIcon,
        uri: "/fortnite/overlays",
      },
      {
        label: ["welcome:usp.battles", "Battles"],
        icon: BattlesIcon,
        uri: "/battles/fortnite",
      },
    ],
    featureTitle: [
      "welcome:fortniteFeatureTitle",
      "Data-Driven Insights on Every Weapon and Utility You Use in Fortnite",
    ],
    features: [
      {
        title: [
          "welcome:fortnite.feature.statsOverlay",
          "Dynamic Stats Overlay",
        ],
        desc: [
          "welcome:fortnite.feature.statsOverlayDesc",
          "Track valuable metrics over time to measure your gaming performance.",
        ],
        icon: OverlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/fortnite/feat-statOverlay-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/fortnite/feat-statOverlay-img_tray.webp`,
      },
      {
        title: ["welcome:fortnite.feature.weaponStats", "Weapon Statistics"],
        desc: [
          "welcome:fortnite.feature.weaponStatsDesc",
          "Check out the most popular weapons used and crack the meta.",
        ],
        icon: StatisticsIcon,
        bgImg: `${appURLs.CDN}/welcome/main/fortnite/feat-weapStat-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/fortnite/feat-weapStat-img_1.webp`,
      },
      {
        title: ["welcome:fortnite.feature.metrics", "Live Game Combat Metrics"],
        desc: [
          "welcome:fortnite.feature.metricsDesc",
          "Track key combat metrics in real time as you play.",
        ],
        icon: PlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/fortnite/feat-metric-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/fortnite/feat-metric-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_CS2]: {
    color: CS2Def.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/cs2/bg-img_1.webp`,
    },
    headline: [
      "welcome:cs2Headline",
      "Win up to 30% more games with Blitz’s Counter-Strike 2 Performance Tracker",
    ],
    compliancy: "valve",
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/cs2/hero-img_1.webp`,
      alt: ["welcome:cs2HeroAlt", "Demo of Blitz profile page"],
    },
    usps: [
      {
        label: ["welcome:usp.statistics", "Statistics"],
        icon: StatisticsIcon,
        uri: "/cs2/stats",
      },
      {
        label: ["welcome:usp.leaderboards", "Leaderboards"],
        icon: LeaderboardIcon,
        uri: "/cs2/leaderboard",
      },
      {
        label: ["welcome:usp.weaponDatabase", "Weapons Database"],
        icon: DatabaseIcon,
        uri: "/cs2/database/weapons",
      },
      {
        label: ["welcome:usp.overlay", "Overlays"],
        icon: OverlayIcon,
        uri: "/overlays/cs2",
      },
    ],
    featureTitle: [
      "welcome:cs2FeatureTitle",
      "Discover How you Compare with the Best in the Game",
    ],
    features: [
      {
        title: ["welcome:cs2.feature.maps", "Maps"],
        desc: [
          "welcome:cs2.feature.mapsDesc",
          "Gain the competitive edge in CS2 with detailed map statistics and insights. Understand map strengths and weaknesses for better game strategies.",
        ],
        icon: MapIcon,
        bgImg: `${appURLs.CDN}/welcome/main/cs2/feat-maps-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/cs2/feat-maps-img_1.webp`,
      },
      {
        title: ["welcome:cs2.feature.statsOverlay", "Dynamic Stats Overlay"],
        desc: [
          "welcome:cs2.feature.statsOverlayDesc",
          "Track key combat metrics in real time and compare them to the best in the lobby.",
        ],
        icon: OverlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/cs2/feat-statOverlay-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/cs2/feat-statOverlay-img_1.webp`,
      },
      {
        title: ["welcome:cs2.feature.weaponStats", "Weapon Statistics"],
        desc: [
          "welcome:cs2.feature.weaponStatsDesc",
          "Make every shot count with our CS2 weapon stats. Improve your accuracy, weapon choice, and performance to dominate your opponents.",
        ],
        icon: StatisticsIcon,
        bgImg: `${appURLs.CDN}/welcome/main/cs2/feat-weapStat-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/cs2/feat-weapStat-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_LOL]: {
    color: LoLDef.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/lol/bg-img_1.webp`,
    },
    headline: [
      "welcome:lolHeadline",
      "Win 30% More with Blitz's highest win-rate builds and items",
    ],
    compliancy: "riot",
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/lol/hero-img_1.webp`,
      alt: ["welcome:lolHeroAlt", "Demo of Blitz profile page"],
    },
    usps: [
      {
        label: ["welcome:usp.profile", "Profile & Match History"],
        icon: AvatarIcon,
        // idk what to do with this
        uri: "/lol/probuilds/history",
      },
      {
        label: ["welcome:usp.benchmarkingOverlay", "Benchmarking Overlay"],
        icon: OverlayIcon,
        uri: "/overlays/lol?select=benchmark",
      },
      {
        label: ["welcome:usp.battles", "Blitz Battles"],
        icon: BattlesIcon,
        uri: "/battles/lol",
      },
      {
        label: ["welcome:lolUsp.aramTimers", "ARAM Health Timers Overlay"],
        icon: AramIcon,
        uri: "/overlays/lol?select=healthTimer",
      },
    ],
    featureTitle: [
      "welcome:lolFeatureTitle",
      "Never enter a League match with the wrong build",
    ],
    features: [
      {
        title: [
          "welcome:lol.feature.autoImport",
          "Auto-Import Runes & Item Builds",
        ],
        desc: [
          "welcome:lol.feature.autoImportDesc",
          "Gain the edge to dominate your competition with auto-imported top-tier runes, summoner spells, and item builds.",
        ],
        icon: RuneDomination,
        bgImg: `${appURLs.CDN}/welcome/main/lol/feat-autoImport-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/lol/feat-autoImport-img_1.webp`,
      },
      {
        title: [
          "welcome:lol.feature.postmatch",
          "Post Match Performance Evaluation",
        ],
        desc: [
          "welcome:lol.feature.postmatchDesc",
          "Improve your skills with our post-match evaluation feature that identifies strengths and weaknesses in key areas of gameplay.",
        ],
        icon: PostmatchEval,
        bgImg: `${appURLs.CDN}/welcome/main/lol/feat-postmatch-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/lol/feat-postmatch-img_1.webp`,
      },
      {
        title: ["welcome:lol.feature.overlay", "In-Game Performance Overlay"],
        desc: [
          "welcome:lol.feature.overlayDesc",
          "Level up your gameplay with our in-game overlay that displays real-time stat goals and tracks your progress.",
        ],
        icon: OverlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/lol/feat-overlay-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/lol/feat-overlay-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_VAL]: {
    color: ValDef.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/valorant/bg-img_1.webp`,
    },
    headline: [
      "welcome:valorantHeadline",
      "Improve your Win-rate by 30% with our Valorant Performance Tracker",
    ],
    compliancy: "riot",
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/valorant/hero-img_1.webp`,
      alt: ["welcome:valorantHeroAlt", "Demo of Blitz profile page"],
    },
    usps: [
      {
        label: ["welcome:usp.overlay", "Overlays"],
        icon: OverlayIcon,
        uri: "/overlays/valorant",
      },
      {
        label: ["welcome:usp.statistics", "Statistics"],
        icon: StatisticsIcon,
        uri: "/valorant/stats",
      },
      {
        label: ["welcome:usp.leaderboards", "Leaderboards"],
        icon: LeaderboardIcon,
        uri: "/valorant/leaderboards/official",
      },
      {
        label: ["welcome:usp.lineups", "Lineups"],
        icon: MapIcon,
        uri: "/valorant/lineups",
      },
    ],
    featureTitle: [
      "welcome:valorantFeatureTitle",
      "Climb the ranks with our leaderboard tracker, showcasing the top players, and their competitive standings.",
    ],
    features: [
      {
        title: [
          "welcome:valorant.feature.agentOverlay",
          "Agent Select Overlay",
        ],
        desc: [
          "welcome:valorant.feature.agentOverlayDesc",
          "See players' ranks and statistics from their last 5 competitive matches in agent select",
        ],
        icon: OverlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/valorant/feat-agentOverlay-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/valorant/feat-agentOverlay-img_1.webp`,
      },
      {
        title: ["welcome:valorant.feature.postmatch", "Post Match Insights"],
        desc: [
          "welcome:valorant.feature.postmatchDesc",
          "Automatically view and benchmark key stats as soon as your match ends",
        ],
        icon: PostmatchEval,
        bgImg: `${appURLs.CDN}/welcome/main/valorant/feat-postmatch-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/valorant/feat-postmatch-img_1.webp`,
      },
      {
        title: ["welcome:valorant.feature.lineup", "Lineup Guide"],
        desc: [
          "welcome:valorant.feature.lineupDesc",
          "Review the best attacking and defending positions",
        ],
        icon: MapIcon,
        bgImg: `${appURLs.CDN}/welcome/main/valorant/feat-lineup-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/valorant/feat-lineup-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_APEX]: {
    color: ApexDef.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/apex/bg-img_1.webp`,
    },
    headline: [
      "welcome:apexHeadline",
      "Win 30% More Games with our Apex Legends Performance Tracker",
    ],
    compliancy: "ea",
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/apex/hero-img_1.webp`,
      alt: ["welcome:apexHeroAlt", "Demo of Blitz profile page"],
    },
    usps: [
      {
        label: ["welcome:apexUsp.stats", "Track Key Stats"],
        icon: StatisticsIcon,
        uri: "/overlays/apex?select=postMatch",
      },
      {
        label: ["welcome:apexUsp.compareRank", "Compare Rank"],
        icon: ApexMaster,
        uri: "/overlays/apex?select=postMatch",
      },
      {
        label: ["welcome:apexUsp.overlay", "Dynamic Overlay"],
        icon: OverlayIcon,
        uri: "/overlays/apex?select=combat",
      },
      {
        label: ["welcome:apexUsp.weaponsGuide", "Weapons Guide"],
        icon: ApexKda,
        uri: "/overlays/apex?select=combat",
      },
    ],
    featureTitle: [
      "welcome:apexFeatureTitle",
      "Track Key Performance Metrics Live Compared to the Best in the Lobby",
    ],
    features: [
      {
        title: [
          "welcome:apex.feature.postmatch",
          "Post Match Insights Overlay",
        ],
        desc: [
          "welcome:apex.feature.postmatchDesc",
          "Automatically pop up in-depth statistics as soon as your game ends.",
        ],
        icon: PostmatchEval,
        bgImg: `${appURLs.CDN}/welcome/main/apex/feat-postmatch-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/apex/feat-postmatch-img_1.webp`,
      },
      {
        title: ["welcome:apex.feature.legendSelect", "Legend Select"],
        desc: [
          "welcome:apex.feature.legendSelectDesc",
          "Compare your top legends' stats and choose the best option on legend select.",
        ],
        icon: ApexLogo,
        bgImg: `${appURLs.CDN}/welcome/main/apex/feat-legendSelect-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/apex/feat-legendSelect-img_1.webp`,
      },
      {
        title: ["welcome:apex.feature.stats", "Dynamic Stats"],
        desc: [
          "welcome:apex.feature.statsDesc",
          "Track key combat metrics in real time and compare them to the best in the lobby.",
        ],
        icon: OverlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/apex/feat-stats-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/apex/feat-stats-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_TFT]: {
    color: TFTDef.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/tft/bg-img_1.webp`,
    },
    headline: [
      "welcome:tftHeadline",
      "Win 30% more with Blitz's highest win-rate compositions.",
    ],
    compliancy: "riot",
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/tft/hero-img_1.webp`,
      alt: [
        "welcome:tftHeroAlt",
        "Demo of Blitz team composition recommendations",
      ],
    },
    usps: [
      {
        label: ["welcome:tftUsp.stats", "Composition Stats"],
        icon: CompIcon,
        uri: "/tft/comps",
      },
      {
        label: ["welcome:tftUsp.augment", "Augment Stats & Info Overlay"],
        icon: AugmentIcon,
        uri: "/overlays/tft?select=augmentInfo",
      },
      {
        label: ["welcome:tftUsp.matchup", "Matchup Tracking Overview"],
        icon: OverlayIcon,
        uri: "/overlays/tft?select=matchTracking",
      },
      {
        label: ["welcome:usp.proBuilds", "Pro Builds"],
        icon: ProBuildsIcon,
        uri: "/tft/probuilds",
      },
    ],
    featureTitle: [
      "welcome:tftFeatureTitle",
      "Data-Driven Insights on Every Weapon and Utility You Use in TFT",
    ],
    features: [
      {
        title: ["welcome:tft.feature.35Revival", "TFT Set 3.5 Revival"],
        desc: [
          "welcome:tft.feature.35RevivalDesc",
          "Set 3.5 is back. Check out our Set Info and Comps pages",
        ],
        icon: TFTSet35,
        bgImg: `${appURLs.CDN}/welcome/main/tft/feat-35Revival-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/tft/feat-35Revival-img_1.webp`,
      },
      {
        title: ["welcome:tft.feature.earlyComp", "Early Comps"],
        desc: [
          "welcome:tft.feature.earlyCompDesc",
          "Discover the best TFT early game comps and opener builds in the meta",
        ],
        icon: CompIcon,
        bgImg: `${appURLs.CDN}/welcome/main/tft/feat-earlyComp-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/tft/feat-earlyComp-img_1.webp`,
      },
      {
        title: ["welcome:tft.feature.overlay", "Benchmarking Overlay"],
        desc: [
          "welcome:tft.feature.overlayDesc",
          "Track key performance metrics to better gauge performance in real-time",
        ],
        icon: OverlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/tft/feat-overlay-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/tft/feat-overlay-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_EFT]: {
    color: EFTDef.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/eft/bg-img_1.webp`,
    },
    headline: [
      "welcome:eftHeadline",
      "Improve Your EFT Raids & Start Tracking Your Stats with the Blitz Desktop App Today",
    ],
    compliancy: "battleState",
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/eft/hero-img_1.webp`,
      alt: ["welcome:eftHeroAlt", "Demo of Blitz profile page"],
    },
    usps: [
      {
        label: ["welcome:eftUsp.statistics", "Personalized Statistics"],
        icon: StatisticsIcon,
        // uri: "/eft/stats",
      },
      {
        label: ["welcome:usp.leaderboards", "Leaderboards"],
        icon: LeaderboardIcon,
        // uri: "/eft/leaderboards",
      },
      {
        label: ["welcome:eftUsp.database", "Maps, Flea Market & Database"],
        icon: DatabaseIcon,
        // uri: "/eft/database",
      },
    ],
    featureTitle: [
      "welcome:eftFeatureTitle",
      "Unlock Your Potential with our EFT Performance features",
    ],
    features: [
      {
        title: ["welcome:eft.feature.stats", "Personalized Weapon Statistics"],
        desc: [
          "welcome:eft.feature.statsDesc",
          "Gain the competitive edge in EFT with personaized weapon statistics and insights.",
        ],
        icon: MapIcon,
        bgImg: `${appURLs.CDN}/welcome/main/eft/feat-stats-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/eft/feat-stats-img_1.webp`,
      },
      {
        title: ["welcome:eft.feature.overlay", "Real-Time Raid Overlays"],
        desc: [
          "welcome:eft.feature.overlayDesc",
          "Get the most out of each run with our real-time raid overlays",
        ],
        icon: StatisticsIcon,
        bgImg: `${appURLs.CDN}/welcome/main/eft/feat-overlay-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/eft/feat-overlay-img_1.webp`,
      },
      {
        title: ["welcome:eft.feature.database", "Maps, Flea Market & Database"],
        desc: [
          "welcome:eft.feature.databaseDesc",
          "Keep track of Flee Market prices, weapons, and map info with our comprehensive database.",
        ],
        icon: OverlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/eft/feat-database-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/eft/feat-database-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_DESTINY2]: {
    color: DestinyDef.gameColor,
    bg: {
      img: `${appURLs.CDN}/welcome/main/destiny2/bg-img_1.webp`,
    },
    headline: [
      "welcome:destiny2Headline",
      "Streamline Your Destiny 2 Performance & Start Tracking Your Stats with the Blitz Desktop App Today",
    ],
    compliancy: "bungie",
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/destiny2/hero-img_4.webp`,
      alt: ["welcome:destiny2HeroAlt", "Demo of Blitz profile page"],
    },
    usps: [
      {
        label: ["welcome:destiny2Usp.statistics", "Personalized Statistics"],
        icon: StatisticsIcon,
        // uri: "/eft/stats",
      },
      {
        label: ["welcome:destiny2Usp.database", "Comprehensive Database"],
        icon: DatabaseIcon,
        // uri: "/eft/database",
      },
    ],
    featureTitle: [
      "welcome:destinyFeatureTitle",
      "Blitz.gg is your ultimate companion for managing in-game items, crafting perfect loadouts, and optimizing your gameplay. Whether you’re a casual player or a competitive gamer, Blitz.gg makes it easier to stay organized and efficient",
    ],
    features: [
      {
        title: [
          "welcome:destiny2.feature.itemManagement",
          "Item Management, Simplified",
        ],
        desc: [
          "welcome:destiny2.feature.itemManagementDesc",
          "Easily browse, sort, and filter through your entire inventory. No more struggling to find the gear you need—our intuitive interface ensures you can quickly access your best equipment.",
        ],
        icon: PlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/destiny2/feat-itemManagement-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/destiny2/feat-itemManagement-img_1.webp`,
      },
      {
        title: [
          "welcome:destiny2.feature.itemTransfer",
          "Build Loadouts, Faster",
        ],
        desc: [
          "welcome:destiny2.feature.itemTransferDesc",
          "Need to move gear between characters or to the vault? With Blitz.gg, item transfers are fast, secure, and happen without needing to log out or slow down your play.",
        ],
        icon: StatisticsIcon,
        bgImg: `${appURLs.CDN}/welcome/main/destiny2/feat-itemTransfer-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/destiny2/feat-itemTransfer-img_1.webp`,
      },
      {
        title: [
          "welcome:destiny2.feature.optimize",
          "Optimize and Customize Your Experience",
        ],
        desc: [
          "welcome:destiny2.feature.optimizeDesc",
          "Personalize how you view and manage your gear. Our customizable interface allows you to adjust settings, highlight stats, and organize items in a way that suits your playstyle.",
        ],
        icon: OverlayIcon,
        bgImg: `${appURLs.CDN}/welcome/main/destiny2/feat-optimize-bg_1.webp`,
        src: `${appURLs.CDN}/welcome/main/destiny2/feat-optimize-img_1.webp`,
      },
    ],
  },
  [GAME_SYMBOL_MARVEL_RIVALS]: {
    color: MarvelRivalsDef.gameColor,
    bg: {
      img: "",
    },
    headline: [
      "welcome:marvelRivalsHeadline",
      "Improve your team synergy with real-time, in-game overlays, with the Blitz app",
    ],
    heroImg: {
      src: `${appURLs.CDN}/welcome/main/marvel-rivals/hero_img_1.webp`,
      alt: ["welcome:marvelRivalsHeroAlt", "Demo of Marvel Rivals overlay"],
    },
    usps: [
      {
        label: ["welcome:usp.overlay", "Overlays"],
        icon: OverlayIcon,
        uri: "/overlays/marvel-rivals",
      },
      {
        label: ["welcome:marvelRivals.usp.tierlist", "Hero Tierlist"],
        icon: `${appURLs.CDN}/welcome/main/marvel-rivals/tier-list-icon.webp`,
        uri: "/marvel-rivals/tierlist",
      },
      {
        label: [
          "welcome:marvelRivals.usp.heroInfo",
          "Hero Guides and Strategies",
        ],
        icon: DatabaseIcon,
        uri: "/marvel-rivals",
      },
    ],
    featureTitle: [
      "welcome:marvelRivalsFeatureTitle",
      "Data-Driven insights for better team play",
    ],
    features: [
      {
        title: [
          "welcome:marvelRivals.feature.leaderboard",
          "Real-Time Leaderboard",
        ],
        desc: [
          "welcome:marvelRivals.feature.leaderboardDesc",
          "See how you stack up against enemies and your teammates. Compare in-game damage, heal, kills, deaths, and assists in real-time.",
        ],
        icon: LeaderboardIcon,
        // bgImg: `${appURLs.CDN}/welcome/main/marvel-rivals/feature_bg_1.webp`,
        bgImg: "",
        src: `${appURLs.CDN}/welcome/main/marvel-rivals/leaderboard_img_2.webp`,
      },
      {
        title: ["welcome:marvelRivals.feature.healthboard", "Health Tracking"],
        desc: [
          "welcome:marvelRivals.feature.healthboardDesc",
          "Keep an eye on your team. Know when it's time to heal, and when it's time to push.",
        ],
        icon: HealthIcon,
        // bgImg: `${appURLs.CDN}/welcome/main/marvel-rivals/feature_bg_1.webp`,
        bgImg: "",
        src: `${appURLs.CDN}/welcome/main/marvel-rivals/Healthboard.webp`,
      },
      {
        title: [
          "welcome:marvelRivals.feature.ultimateTracker",
          "Ultimate Energy",
        ],
        desc: [
          "welcome:marvelRivals.feature.ultimateTrackerDesc",
          "Improve your team coordination with our ultimate energy tracker. Know when your team is ready to unleash their ultimate abilities.",
        ],
        icon: LightningIcon,
        // bgImg: `${appURLs.CDN}/welcome/main/marvel-rivals/feature_bg_1.webp`,
        bgImg: "",
        src: `${appURLs.CDN}/welcome/main/marvel-rivals/ultimate_img_1.webp`,
      },
    ],
  },
};
